import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | ImaginATE Restaurant",
  pageDescription:
    "Discover how a fine dining restaurant gives guests faster service with handhelds, QR codes, and SpotOn’s full-featured POS system.",
  title: "Fine dining reimagined",
  subTitle: `How ImaginATE Restaurant uses SpotOn to wow its guests.`,
  businessName: "ImaginATE Restaurant",
  industry: "Restaurants & hospitality, fine dining",
  location: "Royal Oak, Michigan",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.SERVE,
    spotonProducts.ORDER,
  ],

  description:
    "At ImaginaATE, dinner isn’t just dinner—it’s an experience. And to make that experience even more memorable, general manager Candace Lilly uses SpotOn to impress guests with lightning-fast impeccable service.",
  quoteData: {
    imgName: "imaginate-restaurant.png",
    quote: `“SpotOn is very user-friendly. It took me 15 minutes to explain the restaurant point-of-sale system to my staff, and that’s it. The QR code scan and pay feature, ability to access from your phone, customer service, integrations—it’s all a big plus.”`,
    personName: "Candace Lilly",
    personTitle: "General Manager, Imaginate Restaurant",
  },
  goal: `Imaginate needed a POS that could do it all—make schedule and menu changes on the fly, enable servers to take action on requests instantly, and empower guests to order and pay at any time.`,
  solution: `With SpotOn Restaurant, Candace found every tool she needed in one place. SpotOn’s manager app gave her the ability to make adjustments from anywhere. And while SpotOn Serve gave servers a fully-functional handheld POS, SpotOn QR codes allowed customers to order and pay right from their phone.`,
  results: {
    title: `The results`,
    stats: [
      { title: `20 min.`, description: `decrease in closeout times` },
      {
        title: `10%`,
        description: `increase in revenue`,
      },
      { title: `10 min.`, description: `faster order times` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "A southern restaurant sees profits go north",
    imageName: "southern-table.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-28",
    href: "/case-studies/southern-table",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Beer, beef, and baseball",
    imageName: "von-elrods-beer-hall-and-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-21",
    href: "/case-studies/von-elrods-beer-hall-and-kitchen",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
